$dependencyManager.addDependencyCallback('swiper', () => {
  class ImageCardsSlider {
    constructor(container) {
      this.container = jQuery(container);
      this.slider = this.container.find('> .mad-slider');
      this.sliderId = this.container.data('slider-id');
      this.nextSlideDelay = 3000;
      this.nextSlideSpeed = 2000;
      this.swiper = null;
      this.initSlider();
    }

    initSlider() {
      this.swiper = new Swiper(`.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__slides-container`, {
        effect: "cards",
        // grabCursor: true,
        loop: false,
        allowTouchMove: false,
        // autoplay: {
        //   delay: 1000
        // },
        speed: this.nextSlideSpeed,
        cardsEffect: {
          perSlideOffset: 12,
          perSlideRotate: 6,
        },
        rewind: true,
        updateOnWindowResize: true
      });

      this.container.css('opacity', '1');
      setTimeout((function () {this.nextSlide();}).bind(this), this.nextSlideDelay);
    }

    nextSlide() {
      this.swiper.slideNext();
      setTimeout((function () {this.nextSlide();}).bind(this), this.nextSlideDelay);
    }
  }

  jQuery('.mad-slider__container--image-cards').each(function() {
    new ImageCardsSlider(this);
  });
});
