jQuery(document).ready(function() {
    var featureRows = jQuery(".mad-comparison-table__row");

    featureRows.on('mouseenter', function() {
        var cells = jQuery(this).find('td .mad-comparison-table__cell');

        // Loop through each cell in the row
        cells.each(function(index) {
            var cell = jQuery(this);
            var headerImage = jQuery(".mad-comparison-table__header-image[data-column='" + (index + 1) + "']");

            if (cell.hasClass("mad-comparison-table__cell--full")) {
                headerImage.removeClass("inactive").removeClass("active-half").addClass("active-full");
            } else if (cell.hasClass("mad-comparison-table__cell--half")) {
                headerImage.removeClass("inactive").removeClass("active-full").addClass("active-half");
            } else {
                headerImage.removeClass("active-full").removeClass("active-half").addClass("inactive");
            }
        });
    });

    featureRows.on('mouseleave', function() {
        // Remove all active/inactive classes when the mouse leaves the row
        jQuery(".mad-comparison-table__header-image")
            .removeClass("active-full active-half inactive");
    });
});

jQuery(document).ready(function () {
    var dropdown = jQuery('#comparisonTableDropdown');
    var imagePlaceholder = jQuery('#selectedPageImage img');

    dropdown.on('change', function() {
        var selectedValue = jQuery(this).val();
        var selectedImage = jQuery(this).find('option:selected').data('image');
        
        // Hide all comparison tables first
        jQuery('.comparison-table-page').hide();

        // Show the selected comparison table
        if (selectedValue) {
            jQuery('#' + selectedValue).css('display', 'flex');
            
            // Update the image above the dropdown
            imagePlaceholder.attr('src', selectedImage).show(); // Show the image if hidden
        } else {
            imagePlaceholder.hide(); // Hide the image if no page selected
        }
    });
});
