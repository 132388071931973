class PageFilterButtons {
    constructor(containerId) {
        this.containerId = containerId;
        this.$filterButtonsContainer = jQuery(`.mad-filter-buttons__container[data-pages-id='${this.containerId}']`);
        this.$filterButtons = this.$filterButtonsContainer.find('.mad-filter-buttons .filter-button');
        this.$pageContainer = jQuery(`.mad-pages__container[data-pages-id='${this.containerId}'] .mad-pages`);
  
        this.init();
    }
  
    init() {
        // Attach event listeners to filter buttons
        this.$filterButtons.on('click', (event) => {
            const $button = jQuery(event.currentTarget);
            const filterValue = $button.data('filter');
  
            // Remove the active class from all buttons and add it to the clicked button
            this.$filterButtons.removeClass('active');
            $button.addClass('active');
  
            this.filterItems(filterValue);
        });
    }
  
    filterItems(filterValue) {
        const $items = this.$pageContainer.find('.mad-pages__page-container');
  
        if (filterValue === 'all') {
            // Remove the 'disabled' class from all items
            $items.removeClass('disabled');
        } else {
            // Add 'disabled' class to all items and remove it only from the filtered ones
            $items.addClass('disabled');
            $items.filter(function() {
                const $item = jQuery(this);
                const keywords = $item.data('keywords').split(' ');
                return keywords.includes(filterValue);
            }).removeClass('disabled');
        }
    }
  }
  
  jQuery(document).ready(function() {
    // Initialize the filter buttons for each filter button container found on the page
    jQuery('.mad-filter-buttons__container').each(function() {
        const containerId = jQuery(this).data('pages-id');
        new PageFilterButtons(containerId);
    });
  });
  