const CookieBanner = require("./CookieBanner");
const CookieModal = require("./CookieModal");
const CookieConsentAjax = require("./CookieConsentAjax");

class CookieConsent {
  constructor(settings) {
    this.settings = settings;
    this.consent = {}; // Initialize consent state
    this.cookiePrefix = "mad_consent_"; // Define a consistent prefix
    this.ajax = new CookieConsentAjax(settings.nonce, settings.ajaxurl);

    // Pass this instance to subcomponents
    this.banner = new CookieBanner(this);
    this.modal = new CookieModal(this);

    this.init();
  }

  init() {
    // Read consent from cookies
    this.readConsentFromCookies();

    // Apply existing consent
    this.applyConsent();

    // Show banner if needed
    if (!this.hasConsent()) {
      this.banner.show();
    }
  }

  hasConsent() {
    // Determine if any consent has been given
    return Object.keys(this.consent).length > 0;
  }

  // Consent State Management

  readConsentFromCookies() {
    const consent = {};
    const categoryCookieMap = this.settings.categoryCookieMap;

    Object.keys(categoryCookieMap).forEach((categorySlug) => {
      let categoryConsentGiven = false;
      const categoryConsent = {};

      if (this.checkCookie("category_" + categorySlug)) {
        // User has given consent to the entire category
        this.getCookiesByCategory(categorySlug).forEach((cookieSlug) => {
          categoryConsent[cookieSlug] = true;
        });
        categoryConsentGiven = true;
      } else {
        // Check individual cookies within the category
        this.getCookiesByCategory(categorySlug).forEach((cookieSlug) => {
          if (this.checkCookie(cookieSlug)) {
            categoryConsent[cookieSlug] = true;
            categoryConsentGiven = true;
          }
        });
      }

      if (categoryConsentGiven) {
        consent[categorySlug] = categoryConsent;
      }
    });

    this.consent = consent;
  }

  acceptAll() {
    // Grant consent for all categories and cookies
    Object.keys(this.settings.categoryCookieMap).forEach((categorySlug) => {
      this.giveCookieCategoryConsent(categorySlug);
    });

    // Set a cookie to hide the banner
    this.setCookie("hide_cookie_banner", "true", 365);

    // Apply consent
    this.applyConsent();

    // Log consent
    this.logConsent();

    this.banner.hide();
    this.modal.hide();
  }

  giveCookieConsent(cookieName) {
    const categorySlug = this.getCategoryByCookie(cookieName);
    if (!this.consent[categorySlug]) this.consent[categorySlug] = {};
    this.consent[categorySlug][cookieName] = true;
    this.setCookie("cookie_" + cookieName, "true", 365);
    this.onCookieConsentGiven(cookieName);
  }

  giveCookieCategoryConsent(categorySlug) {
    const cookies = this.getCookiesByCategory(categorySlug);
    if (!this.consent[categorySlug]) this.consent[categorySlug] = {};

    if (cookies && cookies.length > 0) {
      cookies.forEach((cookieName) => {
        this.consent[categorySlug][cookieName] = true;
        this.setCookie("cookie_" + cookieName, "true", 365);
        this.onCookieConsentGiven(cookieName);
      });
    }

    this.setCookie("category_" + categorySlug, "true", 365);
    this.onCookieCategoryConsentGiven(categorySlug);
  }

  revokeCookieConsent(cookieName) {
    const categorySlug = this.getCategoryByCookie(cookieName);
    if (!this.consent[categorySlug]) {
      this.consent[categorySlug] = {};
    }
    this.consent[categorySlug][cookieName] = false;
    // Set the cookie to "false" instead of deleting it
    this.setCookie("cookie_" + cookieName, "false", 365);
    this.onCookieConsentRevoked(cookieName);
  }


  revokeCookieCategoryConsent(categorySlug) {
    const cookies = this.getCookiesByCategory(categorySlug);
    if (!this.consent[categorySlug]) {
      this.consent[categorySlug] = {};
    }
    if (cookies && cookies.length > 0) {
      cookies.forEach((cookieName) => {
        this.consent[categorySlug][cookieName] = false;
        // Set the cookie to "false"
        this.setCookie("cookie_" + cookieName, "false", 365);
        this.onCookieConsentRevoked(cookieName);
      });
    }
    // Set the category cookie to "false"
    this.setCookie("category_" + categorySlug, "false", 365);
    this.onCookieCategoryConsentRevoked(categorySlug);
  }

  getCookieConsent(cookieName) {
    return this.checkCookie("cookie_" + cookieName);
  }

  getCookieCategoryConsent(categorySlug) {
    return this.checkCookie("category_" + categorySlug);
  }

  // Cookie Handling
  setCookie(name, value, days) {
    name = this.cookiePrefix + name;
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 86400000); // 24*60*60*1000
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }

  getCookie(name) {
    name = this.cookiePrefix + name;
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let c of ca) {
      while (c.charAt(0) === " ") c = c.substring(1);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length);
    }
    return null;
  }

  checkCookie(name) {
    return this.getCookie(name) !== null;
  }

  deleteCookie(name) {
    name = this.cookiePrefix + name;
    document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;`;
  }

  // Applying Consent
  applyConsent() {
    // Activate or deactivate scripts based on the current consent state
    Object.keys(this.consent).forEach((categorySlug) => {
      Object.keys(this.consent[categorySlug]).forEach((cookieSlug) => {
        if (this.consent[categorySlug][cookieSlug]) {
          this.activateScriptsByCookieName(cookieSlug);
        } else {
          this.deactivateScriptsByCookieName(cookieSlug);
        }
      });
    });
  }

  // Script Activation/Deactivation

  activateScriptsByCookieName(cookieName) {
    jQuery(
      `script[data-cookie="${cookieName}"][type="application/script-template"]`
    ).each(function () {
      const script = jQuery(this);
      const newScript = jQuery("<script>", {
        type: "application/javascript",
        html: script.html(),
      });

      jQuery.each(this.attributes, function () {
        if (this.name !== "type") {
          newScript.attr(this.name, this.value);
        }
      });

      script.replaceWith(newScript);
    });
  }

  deactivateScriptsByCookieName(cookieName) {
    jQuery(
      `script[data-cookie="${cookieName}"][type="application/javascript"]`
    ).remove();
  }

  // Event Handlers (Activation Hooks)

  onCookieConsentGiven(cookieSlug) {
    this.activateScriptsByCookieName(cookieSlug);
  }

  onCookieConsentRevoked(cookieSlug) {
    this.deactivateScriptsByCookieName(cookieSlug);
  }

  onCookieCategoryConsentGiven(categorySlug) {
    this.activateScriptsByCategoryName(categorySlug);
  }

  onCookieCategoryConsentRevoked(categorySlug) {
    this.deactivateScriptsByCategoryName(categorySlug);
  }

  activateScriptsByCategoryName(categoryName) {
    jQuery(
      `script[data-cookie-category="${categoryName}"][type="application/script-template"]`
    ).each(function () {
      const script = jQuery(this);
      const newScript = jQuery("<script>", {
        type: "application/javascript",
        html: script.html(),
      });

      jQuery.each(this.attributes, function () {
        if (this.name !== "type") {
          newScript.attr(this.name, this.value);
        }
      });

      script.replaceWith(newScript);
    });
  }

  deactivateScriptsByCategoryName(categoryName) {
    jQuery(
      `script[data-cookie-category="${categoryName}"][type="application/javascript"]`
    ).remove();
  }

  // Utility Functions

  getCategoryByCookie(cookieSlug) {
    return this.settings.cookieCategoryMap[cookieSlug];
  }

  getCookiesByCategory(categorySlug) {
    return this.settings.categoryCookieMap[categorySlug];
  }

  // Logging Consent

  logConsent() {
    const allCategories = Object.keys(this.settings.categoryCookieMap);
    const consentData = { consent: {} };

    allCategories.forEach((categorySlug) => {
      const cookies = this.getCookiesByCategory(categorySlug);
      if (cookies && cookies.length > 0) {
        consentData.consent[categorySlug] = {};
        cookies.forEach((cookieName) => {
          const consentGiven = !!(
            this.consent[categorySlug] && this.consent[categorySlug][cookieName]
          );
          consentData.consent[categorySlug][cookieName] = consentGiven;
        });
      }
    });

    this.ajax.logConsent("consent_given", consentData);
  }
}

module.exports = CookieConsent;
