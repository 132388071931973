class Timeline {
    constructor(timelineId) {
        this.timelineId = timelineId;
        this.timelineContainer = jQuery(`#${timelineId}`);
        this.firstEventMarkerContainer = this.timelineContainer.find('.mad-timeline__event-marker-container').first();
        this.firstEventMarker = this.firstEventMarkerContainer.find('.mad-timeline__event-marker');
        this.firstEventContent = this.timelineContainer.find('.mad-timeline__event').first().find('.mad-timeline__event-content');
        this.mainLine = jQuery('<div class="mad-timeline__event-line mad-timeline__event-line--main"></div>');
        this.backgroundLine = jQuery('<div class="mad-timeline__event-line mad-timeline__event-line--background"></div>');

        if (this.firstEventMarkerContainer.length) {            
            this.firstEventMarkerContainer.prepend(this.backgroundLine);
            this.firstEventMarkerContainer.append(this.mainLine);
            this.init();
        }
    }

    setMaxHeight() {
        let totalHeight = 0;
        const firstEvent = this.timelineContainer.find('.mad-timeline__event').first();
        const lastEvent = this.timelineContainer.find('.mad-timeline__event').last();
        const firstEventMarker = this.timelineContainer.find('.mad-timeline__event-marker').first();
        const lastEventMarker = this.timelineContainer.find('.mad-timeline__event-marker').last();
        const firstEventHeight = firstEvent.outerHeight();
        const firstEventMarkerHeight = firstEvent.find('.mad-timeline__event-marker').height();

        totalHeight = lastEventMarker.offset().top - firstEventMarker.offset().top;

        this.backgroundLine.css('height', totalHeight + 'px');
        this.mainLine.css('max-height', totalHeight + 'px');

        // This forces the event markers to be on top
        if (jQuery(window).width() < 768 || true) {
            this.backgroundLine.css('top', firstEventMarkerHeight / 2 + 'px');
            this.mainLine.css('top', firstEventMarkerHeight / 2 + 'px');
        } else {
            this.backgroundLine.css('top', firstEventHeight / 2 + 'px');
            this.mainLine.css('top', firstEventHeight / 2 + 'px');
        }
    }

    updateMainLineHeight() {
        const viewportCenter = jQuery(window).scrollTop() +  window.innerHeight / 2;
        const markerCenter = this.firstEventMarker.offset().top + this.firstEventMarker.height() / 2;

        const newHeight = Math.max(0, viewportCenter - markerCenter);
        this.mainLine.css('height', newHeight + 'px');
    }

    updateActiveClass() {
        const viewportCenter = jQuery(window).scrollTop() +  window.innerHeight / 2;

        this.timelineContainer.find('.mad-timeline__event').each((index, element) => {
            const eventMarker = jQuery(element).find('.mad-timeline__event-marker');
            const eventContent = jQuery(element).find('.mad-timeline__event-content');
            const eventMarkerCenter = jQuery(eventMarker).offset().top + jQuery(eventMarker).height() / 2;

            const isActive = viewportCenter >= eventMarkerCenter;

            jQuery(element).toggleClass('active', isActive);
            eventContent.toggleClass('active', isActive);
            eventMarker.toggleClass('active', isActive);
        });

        // Always keep the first event content active
        this.firstEventContent.addClass('active');
        this.firstEventMarker.addClass('active');
    }

    init() {
        this.setMaxHeight();
        this.updateMainLineHeight();
        this.updateActiveClass();

        jQuery(window).on('scroll', () => {
            this.updateMainLineHeight();
            this.updateActiveClass();
        });

        jQuery(window).on('resize', () => {
            this.setMaxHeight();
            this.updateMainLineHeight();
            this.updateActiveClass();
        });

        setInterval(() => {
            this.setMaxHeight();
        }, 1000)
    }
}

jQuery(document).ready(function () {
    jQuery('.mad-timeline__container').each(function () {
        const timelineId = jQuery(this).attr('id');
        new Timeline(timelineId);
    });
});
