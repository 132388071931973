$dependencyManager.addDependencyCallback('gsap', () => {
  class GroupPanel {
    constructor(groupPanelContainer) {
      this.rows = [];
      this.container = jQuery(groupPanelContainer);
      this.initRows();
      this.setupEventHandlers();
    }

    initRows() {
      this.container.children('.mad-group-panel__row').each((i, element) => {
        this.rows.push(new GroupPanelRow(element));
      });
    }

    setupEventHandlers() {
      this.rows.forEach(row => {
        let radio = row.header.find('input[type="radio"]');
        let checkbox = row.header.find('input[type="checkbox"]');

        if (radio.length > 0) {
          row.mode = 'radio';
          radio.on('change', () => this.handleRadioChange(radio, row));
          if (radio.is(':checked')) {
            row.open();
          }
        } else if (checkbox.length > 0) {
          checkbox.on('change', () => row.checkboxChangeHandler(checkbox));
        }
      });
    }

    handleRadioChange(radio, currentRow) {
      if (radio.is(':checked')) {
        this.rows.forEach(row => {
          if (row.mode === 'radio') {
            if (row === currentRow) {
              row.open();
            } else {
              row.close();
            }
          }
        });
      }
    }
  }
  
  class GroupPanelRow {
    constructor(rowContainer) {
      this.mode = null;
      this.container = jQuery(rowContainer);
      this.header = this.container.children('.mad-group-panel__row-header');
      this.content = this.container.children('.mad-group-panel__row-content');

      if (this.content.length === 0) return;

      this.container.height(this.header.outerHeight(true));
      this.content.show();
    }

    checkboxChangeHandler(checkbox) {
      if (checkbox.is(':checked')) {
        this.open();
      } else {
        this.close();
      }
    }

    open() {
      let totalHeight = 0;
      this.container.children().each((index, child) => {
        totalHeight += jQuery(child).outerHeight(true);
      });
    
      gsap.to(this.container, {
        height: totalHeight,
        duration: 0.4
      });
    }

    close() {
      gsap.to(this.container, {
        height: this.header.outerHeight(true),
        duration: 0.4
      });
    }
  }
  
  window.$groupPanels = [];
  jQuery('.mad-group-panel').each((i, element) => {
    let groupPanel = new GroupPanel(element);
    window.$groupPanels.push(groupPanel);
  });
});
