const Animation = require('./Animation');

class FadeInAnimation extends Animation {
  constructor(element, x, y, duration = 0.5) {
    super(element, x, y, duration);
    this.createTimeline();
    super.setupScrollTrigger();
  }

  createTimeline() {
    this.timeline = super.createTimeline();

    this.timeline.fromTo(this.element, {
      opacity: 0,
      x: this.x,
      y: this.y
    }, {
      opacity: 1,
      x: 0,
      y: 0,
      duration: this.duration
    });

    return this.timeline;
  }
}

module.exports = FadeInAnimation;
