$dependencyManager.addDependencyCallback('swiper', () => {
  const updateSliderSettings = () => {
    const sliders = document.querySelectorAll('.mad-auto-slider');
    sliders.forEach(slider => {
      const desktopHeight = slider.getAttribute('data-height-desktop');
      const tabletHeight = slider.getAttribute('data-height-tablet');
      const mobileHeight = slider.getAttribute('data-height-mobile');
      const smallMobileHeight = slider.getAttribute('data-height-small-mobile');
      const slidesDesktop = parseInt(slider.getAttribute('data-slides-desktop'), 10);
      const slidesTablet = parseInt(slider.getAttribute('data-slides-tablet'), 10);
      const slidesMobile = parseInt(slider.getAttribute('data-slides-mobile'), 10);
      const slidesSmallMobile = parseInt(slider.getAttribute('data-slides-small-mobile'), 10);
      
      let height = desktopHeight; // Default to desktop height

      if (window.innerWidth <= 576) {
        height = smallMobileHeight; // Small mobile height for widths <= 576px
      } else if (window.innerWidth <= 768) {
        height = mobileHeight; // Mobile height for widths <= 768px
      } else if (window.innerWidth <= 1024) {
        height = tabletHeight; // Tablet height for widths <= 1024px
      }

      slider.style.height = height;

      // Initialize Swiper with breakpoints
      if (slider.swiperInstance) {
        slider.swiperInstance.destroy(true, true);
      }

      slider.swiperInstance = new Swiper(slider.querySelector('.swiper'), {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        speed: 3000,
        centeredSlides: false,
        autoHeight: true,
        updateOnWindowResize: true,
        slidesPerView: slidesSmallMobile,
        breakpoints: {
          320: {
            slidesPerView: slidesMobile,
          },
          768: {
            slidesPerView: slidesTablet,
          },
          1200: {
            slidesPerView: slidesDesktop,
          },
        }
      });
    });
  };

  updateSliderSettings();

  window.addEventListener('resize', updateSliderSettings);

  jQuery('.mad-auto-slider').addClass('initialized');
});
