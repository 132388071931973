$dependencyManager.addDependencyCallback(
  "gsap",
  () => {
    class Accordion {
      constructor(container, toggleCallback = null) {
        console.log("Accordion constructor");
        this.container = container;
        this.toggleCallback = toggleCallback;
        this.items = [];
        this.id = container.attr("id");
        this.hash = container.data("accordion-id");
        this.init();
        Accordion.storeInstance(this);
      }

      init() {
        const items = this.container.find(".mad-accordion__item");
        items.each((index, item) => {
          const accordionItem = new AccordionItem(
            jQuery(item),
            this,
            this.toggleCallback
          );
          this.items.push(accordionItem);
        });

        // Refresh heights on window resize and orientation change
        jQuery(window).on("resize orientationchange", () =>
          this.refreshHeights()
        );
      }

      closeAll(exceptItem = null) {
        this.items.forEach((item) => {
          if (item !== exceptItem) {
            item.close();
          }
        });
      }

      refreshHeights() {
        this.items.forEach((item) => {
          if (item.container.hasClass("active")) {
            item.bodyContainer.css("height", item.body.outerHeight());
          }
        });
      }

      setToggleCallback(toggleCallback) {
        this.toggleCallback = toggleCallback;
        this.items.forEach((item) => {
          item.setToggleCallback(toggleCallback);
        });
      }

      static storeInstance(instance) {
        if (!window.Accordion.instances) {
          window.Accordion.instances = {};
        }
        if (instance.id) {
          window.Accordion.instances[instance.id] = instance;
        }
        if (instance.hash) {
          window.Accordion.instances[instance.hash] = instance;
        }
      }

      static getInstanceById(id) {
        return window.Accordion.instances
          ? window.Accordion.instances[id]
          : null;
      }

      static getInstanceByHash(hash) {
        return window.Accordion.instances
          ? window.Accordion.instances[hash]
          : null;
      }
    }

    class AccordionItem {
      constructor(container, accordion, toggleCallback = null) {
        this.container = container;
        this.accordion = accordion;
        this.toggleCallback = toggleCallback;
        this.header = this.container.find(".mad-accordion__header");
        this.bodyContainer = this.container.find(
          ".mad-accordion__body-container"
        );
        this.body = this.container.find(".mad-accordion__body");
        this.arrow = this.header.find(".mad-accordion__header-arrow span");
        this.init();
      }

      init() {
        this.bodyContainer.css("height", "0");
        this.header.on("click", () => this.toggle());
      }

      toggle() {
        if (this.container.hasClass("active")) {
          this.close();
        } else {
          this.open();
        }
      }

      open(scrollTo = true) {
        this.accordion.closeAll(this);
        const targetHeight = this.body.outerHeight();
        gsap.to(this.bodyContainer[0], {
          height: targetHeight,
          duration: 0.5,
        });
        this.container.addClass("active");
        if (this.toggleCallback) {
          this.toggleCallback();
        }
    
        if (scrollTo)
          setTimeout(() => {
            this.scrollTo();
          }, 350);
    
          // Don't rotate the arrow icon
        if (this.arrow.hasClass("accordion_no_rotate")) {
          const classesToRemove = this.arrow.attr("class").split(/\s+/).filter(cls => cls !== 'accordion_no_rotate' && cls !== 'accordion-icons').join(' ');
          this.arrow.removeClass(classesToRemove);
          this.arrow.addClass(this.arrow.data("secondary-icon"));
        }
      }
    
      close() {
        gsap.to(this.bodyContainer[0], {
          height: 0,
          duration: 0.5,
        });
        this.container.removeClass("active");
        if (this.toggleCallback) {
          this.toggleCallback();
        }
    
          // Don't rotate the arrow icon
        if (this.arrow.hasClass("accordion_no_rotate")) {
          const classesToRemove = this.arrow.attr("class").split(/\s+/).filter(cls => cls !== 'accordion_no_rotate' && cls !== 'accordion-icons').join(' ');
          this.arrow.removeClass(classesToRemove);
          this.arrow.addClass(this.arrow.data("primary-icon"));
        }
      }

      scrollTo() {
        const itemBody = this.container.find('.mad-accordion__body');
        if (!itemBody.length) return;
      
        const itemHeight = itemBody.outerHeight();
        const windowHeight = jQuery(window).height();
        let offset = 0;
        const padding = 35;
      
        // Calculate total offset (admin bar + navbar)
        if (jQuery("#wpadminbar").length) {
          offset += jQuery("#wpadminbar").outerHeight();
        }
        if (jQuery(".mad-navbar__section").length) {
          offset += jQuery(".mad-navbar__section").outerHeight();
        }
      
        // Initial position calculation
        let position;
      
        // Adjust position based on content size
        if (jQuery(window).width() > 768) {
          // Desktop logic
          if (itemHeight + padding * 2 > windowHeight) {
            // Content is larger than the window height
            position = this.container.offset().top - offset - padding;
          } else {
            // Content fits within the window height
            position =
              this.container.offset().top -
              offset -
              windowHeight / 2 +
              itemHeight / 2;
          }
        } else {
          // Mobile logic: Always scroll to the top of the item
          position = this.container.offset().top - offset - padding;
        }
      
        // Adjust position by subtracting the heights of open items above this one
        let heightToSubtract = 0;
      
        // Get all previous accordion items
        const prevItems = this.container.prevAll('.mad-accordion__item');
      
        prevItems.each(function () {
          const item = jQuery(this);
          const bodyContainer = item.find('.mad-accordion__body-container');
          const containerHeight = parseFloat(bodyContainer.css('height'));
      
          if (containerHeight > 0) {
            heightToSubtract += containerHeight;
          }
        });
      
        // Subtract the total height of open items above
        position -= heightToSubtract;
      
        // Ensure position is not negative
        if (position < 0) {
          position = 0;
        }
      
        // Scroll to the adjusted position
        jQuery('html, body').animate(
          {
            scrollTop: position,
          },
          500
        );
      }
      

      setToggleCallback(toggleCallback) {
        this.toggleCallback = toggleCallback;
      }
    }

    window.Accordion = Accordion;
    window.AccordionItem = AccordionItem;

    const accordions = jQuery(".mad-accordion__container");
    accordions.each((index, container) => {
      new Accordion(jQuery(container));
    });
    jQuery(document).trigger("madAccordionInitialized");
  },
  true
);
