class Animation {
  constructor(element, x, y, duration = 0.5) {
    this.element = element;
    this.x = x;
    this.y = y;
    this.duration = duration;
    this.timeline = null;
  }

  createTimeline() {
    return gsap.timeline({
      paused: true,
      defaults: { ease: 'sine.inOut' }
    });
  }

  setupScrollTrigger() {
    if (this.timeline == null) return;

    ScrollTrigger.create({
      trigger: this.element,
      start: "top 90%",
      end: "bottom top",
      delay: 400,
      scrub: true,
      markers: false,
      onEnter: () => this.timeline.play(),
      onLeaveBack: () => this.timeline.reverse(),
      onEnterBack: () => this.timeline.play(),
      onLeave: () => this.timeline.reverse(),
    });
  }
}

module.exports = Animation;
