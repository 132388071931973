class LanguageSwitch {
  constructor(container, options = {}) {
    this.$container = jQuery(container);
    this.$element = this.$container.find('.mad-language-switch');

    this.$eventElement = this.$container;
    this.$navItem = this.$container.parent('.mad-navbar__nav-item');
    if (this.$navItem.length) this.$eventElement = this.$navItem;

    this.$currentLanguageContainer = this.$container.find(
      ".mad-language-switch__current-language-container"
    );
    this.$currentLanguage = this.$currentLanguageContainer.find(
      ".mad-language-switch__current-language"
    );
    this.$languagesContainer = this.$container.find(
      ".mad-language-switch__languages"
    );
    this.$languageContainers = this.$languagesContainer.find(
      ".mad-language-switch__language-container"
    );

    // Get the size of the current language element
    const currentLangSize = this.$currentLanguage.outerWidth();

    // Customizable parameters
    this.options = Object.assign(
      {
        totalAngle: Math.PI / 2,
        radius: 3 * currentLangSize,
        offsetY: currentLangSize / 2,
        lineOffsetY: currentLangSize + 10,
        spacing: currentLangSize + 5,
        verticalLineOffsetY: currentLangSize + 10,
        verticalSpacing: currentLangSize + 10,
        closeDistance: this.$languageContainers.length * currentLangSize + 180,
      },
      options
    );

    // State
    this.languagesVisible = false;
    this.checkPositionInterval = null;
    this.mousePosition = { x: 0, y: 0 };
    this.isOpening = false;
    this.isClosing = false;

    // Timeout for hover interaction
    this.hoverTimeout = null;

    // Initial setup
    this.setupLanguages();
    this.bindEvents();
  }

  setupLanguages() {
    gsap.set(this.$languageContainers, {
      x: 0,
      y: 0,
      opacity: 0,
    });
  }

  bindEvents() {
    // Hover to open languages
    this.$eventElement.on("mouseenter", () => {
      this.hoverTimeout = setTimeout(() => {
        // Trigger hover to open, but delay interaction
        if (!this.languagesVisible && !this.isOpening) {
          this.showLanguages();
        }
      }, 200); // Delay of 200ms before executing the showLanguages function
    });

    // Clear the timeout if the mouse leaves before the delay finishes
    this.$eventElement.on("mouseleave", () => {
      clearTimeout(this.hoverTimeout); // Cancel showing the languages if mouse leaves early
      this.startCloseCheck(); // Start the close check process when mouse leaves
    });

    // Toggle languages on click (works for both desktop and mobile)
    this.$eventElement.on("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.languagesVisible) {
        if (!this.isOpening) {
          this.hideLanguages();
        }
      } else {
        if (!this.isClosing) {
          this.showLanguages();
        }
      }
    });

    // Prevent clicks on language options from closing the menu
    this.$languageContainers.on("click", (e) => {
      e.stopPropagation();
    });

    // Close when clicking outside
    jQuery(document).on("click touchstart", (e) => {
      if (
        !this.$eventElement.is(e.target) &&
        this.$eventElement.has(e.target).length === 0
      ) {
        this.hideLanguages();
      }
    });

    // Capture mouse position for hover checks
    jQuery(window).on("mousemove", (e) => {
      this.mousePosition = { x: e.pageX, y: e.pageY };
    });
  }

  showLanguages() {
    if (this.languagesVisible) return;
    this.languagesVisible = true;
    this.isOpening = true;

    // Add active class
    this.$element.addClass("active");

    // Choose a positioning method
    this.positionLanguagesInVerticalLine();
  }

  hideLanguages() {
    if (!this.languagesVisible) return;
    this.languagesVisible = false;
    this.isClosing = true;

    // Remove active class
    this.$element.removeClass("active");

    // Stop distance checking
    clearInterval(this.checkPositionInterval);

    this.$languageContainers.each((index, element) => {
      gsap.to(element, {
        duration: 0.2,
        x: 0,
        y: 0,
        opacity: 0,
        ease: "none"
      });

      if (index == 0) {
        setTimeout(() => { this.isClosing = false; }, 400);
      }
    });
  }

  // Vertical line positioning function
  positionLanguagesInVerticalLine() {
    const numLanguages = this.$languageContainers.length;
    const { verticalLineOffsetY, verticalSpacing } = this.options;

    this.$languageContainers.each((index, element) => {
      const x = 0; // Directly below the current language, no horizontal shift
      const y = verticalLineOffsetY + index * verticalSpacing;

      gsap.set(element, {
        opacity: 1,
      });

      gsap.to(element, {
        duration: 0.2,
        x: x,
        y: y,
        ease: "none",
      });

      if (index == 0) {
        setTimeout(() => { this.isOpening = false; }, 400);
      }
    });
  }

  // Start an interval to check mouse position relative to the switch
  startCloseCheck() {
    const { closeDistance } = this.options;
    this.checkPositionInterval = setInterval(() => {
      const cursorX = this.mousePosition.x;
      const cursorY = this.mousePosition.y;
      const elementOffset = this.$container.offset();
      const elementWidth = this.$container.outerWidth();
      const elementHeight = this.$container.outerHeight();

      // Check if the cursor is far enough from the element to close
      if (
        cursorX < elementOffset.left - closeDistance ||
        cursorX > elementOffset.left + elementWidth + closeDistance ||
        cursorY < elementOffset.top - closeDistance ||
        cursorY > elementOffset.top + elementHeight + closeDistance
      ) {
        this.hideLanguages();
      }
    }, 100);
  }
}

module.exports = LanguageSwitch;
