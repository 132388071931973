$dependencyManager.addDependencyCallback('swiper', () => {
  class TestimonialsSlider {
    constructor(container) {
      this.container = jQuery(container);
      this.slider = this.container.find('> .mad-slider');
      this.sliderId = this.container.data('slider-id');
      this.swiper = null;
      this.initSlider();
    }

    initSlider() {
      const slideCount = this.container.find('.mad-slider__slide').length
      this.swiper = new Swiper(`.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__slides-container`, {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 0,
        loop: true,
        loopAdditionalSlides: 1,
        slideClass: 'mad-slider__slide',
        wrapperClass: 'mad-slider__slides',
        navigation: {
          nextEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-next`,
          prevEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-prev`,
        },
        // pagination: {
        //   el: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__pagination`,
        //   clickable: true,
        // },
        updateOnWindowResize: true,
        on: {
          slideChangeTransitionEnd: () => {
            swiper.loopFix('')
          }
        }
      });

      this.container.css('opacity', '1');
    }
  }

  jQuery('.mad-slider__container--testimonials').each(function() {
    new TestimonialsSlider(this);
  });
});
