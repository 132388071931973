class Tabs {
  constructor(section) {
    this.section = jQuery(section)
    this.container = this.section.find('>.brz-section__content>.brz-container')
    this.buttonsContainer = this.container.find('>.brz-row__container:first-child')
    this.buttonsRow = this.buttonsContainer.find('>.brz-row')

    
    this.contentContainers = this.container.find('>.brz-row__container:not(:first-child)')

    this.buttons = []
    var buttonContainers = this.buttonsRow.find('>.brz-columns')
    for (let i = 0; i < buttonContainers.length; i++) {
      const buttonContainer = buttonContainers[i];

      const tabButton = new TabButton(buttonContainer, this.contentContainers[i], this)
      this.buttons.push(tabButton)


    }
  }

  deactivateAll() {
    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];
      button.deactivate()
    }
  }
}

class TabButton {
  constructor(container, content, parent) {
    this.container = jQuery(container)
    this.content = jQuery(content)
    this.active = this.container.hasClass('active')
    this.parent = parent

    if (this.active) {
      this.content.show()
    } else {
      this.content.hide()
    }

    this.container.click(this.clickHandler.bind(this))
  }

  activate() {
    this.container.addClass('active')
    this.content.show()
  }

  deactivate() {
    this.container.removeClass('active')
    this.content.hide()
  }

  clickHandler(e) {
    this.parent.deactivateAll()
    this.activate()
  }
}

if (!window.brzStore) {
  window.$madTabs = []
  jQuery('.mad-tabs__section').each((i, tabsSection) => {
    const tabs = new Tabs(tabsSection);
    window.$madTabs.push(tabs)
  })
}