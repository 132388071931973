$dependencyManager.addDependencyCallback('gsap', () => {
  class HotspotImage {
    constructor(imageContainer, data) {
      this.imageContainer = imageContainer;
      this.data = data;

      this.init();
    }

    init() {
      new Hotspots(this.imageContainer, this.data, { enableTooltip: true });
    }
  }

  // Wait until the DOM is fully ready
  jQuery(document).ready(function() {
    // Find all hotspot image containers on the page
    jQuery('.mad-hotspot-image').each(function() {
      const imageContainer = jQuery(this).find('.mad-hotspot-image__image');
      const hotspotDataScript = jQuery(this).next('.mad-hotspot-data');
      let hotspotData = [];

      if (hotspotDataScript.length) {
        try {
          hotspotData = JSON.parse(hotspotDataScript.html());
        } catch (e) {
          console.error('Error parsing hotspot data:', e);
        }
      }

      // Initialize the HotspotImage class for each container
      new HotspotImage(imageContainer, hotspotData);
    });
  });

  jQuery(document).trigger('madHotspotImageInitialized');
}, true);
