$dependencyManager.addDependenciesCallback(['swiper', 'gsap'], () => {
  class VideoTestimonialsSlider {
    constructor(container) {
      this.container = jQuery(container);
      this.slider = this.container.find('> .mad-slider');
      this.sliderId = this.container.data('slider-id');
      this.playButtons = this.container.find('.mad-slider__slide .mad-slider__button-play');
      this.videoLoader = window.VideoLoader ? new window.VideoLoader() : null;
      this.players = {};
      this.swiper = null;
      this.initSlider();
      this.addAnimations();
      this.initVideos();

      this.addClosePopupCallback();
      this.startPeriodicCheck();
    }

    initSlider() {
      const slideCount = this.container.find('.mad-slider__slide-container').length;
      const shouldLoop = slideCount > 3;

      if (slideCount > 0) {
        this.swiper = new Swiper(`.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__slides-container`, {
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 10,
          loop: shouldLoop,
          loopAdditionalSlides: 0,
          slideClass: 'mad-slider__slide-container',
          wrapperClass: 'mad-slider__slides',
          navigation: shouldLoop ? {
            nextEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-next`,
            prevEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-prev`,
          } : false,
          pagination: shouldLoop ? {
            el: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__pagination`,
            clickable: true,
          } : false,
          updateOnWindowResize: true,
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            550: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 25
            },
          }
        });

        if (shouldLoop) {
          this.container.find('.mad-slider__button-next, .mad-slider__button-prev').show();
        } else {
          this.container.find('.mad-slider__button-next, .mad-slider__button-prev').hide();
        }
      }

      setTimeout(() => {
        this.container.css('opacity', '1');
      }, 300);
    }

    addAnimations() {
      this.playButtons.each((index, element) => {
        const playButton = jQuery(element);
        const ripples = playButton.find('.ripple');

        gsap.fromTo(ripples, {
          height: '100%',
          width: '100%',
          opacity: 1
        }, {
          height: '150%',
          width: '150%',
          opacity: 0,
          duration: 1.2,
          ease: "power2.out",
          repeat: -1,
          repeatDelay: 4
        });
      });
    }

    initVideos() {
      this.playButtons.each((index, button) => {
        const $button = jQuery(button);
        const uniqueId = this.generateUniqueId();

        if ($button.data('video-url')) {
          $button.data('unique-id', uniqueId);

          $button.click(() => {
            var videoUrl = $button.data('video-url');
            var container = jQuery('.mad-popup-video__container .brz-column__items');

            if (container.find(`.mad-video-testimonial__container[data-unique-id="${uniqueId}"]`).length === 0) {
              this.loadVideo(container, videoUrl, uniqueId);
            }

            this.showVideo(uniqueId);

            window.openBrizyPopupByChild('.mad-popup-video__container');
          });
        }
      });
    }

    loadVideo(container, videoUrl, uniqueId) {
      container.find('.mad-video-testimonial__container').hide();

      const videoContainer = jQuery('<div>', {
        class: 'mad-video-testimonial__container',
        'data-unique-id': uniqueId,
        css: {
          display: 'none'
        }
      });

      const videoWrapper = jQuery('<div>', {
        class: 'mad-video-testimonial'
      });

      videoContainer.append(videoWrapper);
      container.append(videoContainer);

      if (!this.videoLoader) {
        this.videoLoader = new window.VideoLoader();
      }

      var player = this.videoLoader.load(videoWrapper, videoUrl);
      if (player) {
        this.players[uniqueId] = player; // Map uniqueId to player
      }
    }

    showVideo(uniqueId) {
      jQuery('.mad-video-testimonial__container').hide();
      jQuery(`.mad-video-testimonial__container[data-unique-id="${uniqueId}"]`).show();

      // Play the video automatically
      if (this.players[uniqueId]) {
        this.players[uniqueId].play();
      }
    }

    generateUniqueId() {
      return '_' + Math.random().toString(36).substr(2, 9);
    }

    stopAllVideos() {
      Object.values(this.players).forEach(player => {
        player.pause();
      });
    }

    addClosePopupCallback() {
      const popup = jQuery('.mad-popup-video__container').closest('.brz-popup2');
      const popupId = popup.data('brz-popup');
      if (popupId) {
        brizyPopupAddCloseCallback(popupId, () => {
          this.stopAllVideos();
        });
      }
    }

    startPeriodicCheck() {
      setInterval(() => {
        const popupId = window.getBrizyPopupIdByChild('.mad-popup-video__container');
        if (popupId !== null && !window.isBrizyPopupOpen(popupId)) {
          this.stopAllVideos();
          jQuery('.mad-video-testimonial__container').hide();
        }
      }, 500);
    }

  }

  jQuery('.mad-slider__container--video-testimonials').each(function () {
    new VideoTestimonialsSlider(this);
  });
});