$dependencyManager.addDependencyCallback('gsap', () => {
  class Hotspots {
    constructor(imageContainer, data, options = {}) {
      this.imageContainer = imageContainer;
      this.data = data;
      this.hotspotsContainer = null;

      // Options with default values
      this.enablePulsation = options.enablePulsation || false;
      this.enableTooltip = options.enableTooltip || false;

      this.isPulsating = this.enablePulsation;
      this.isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

      this.init();
    }

    init() {
      const hotspotsContainer = jQuery('<div class="mad-hotspots__container"></div>');
      this.imageContainer.append(hotspotsContainer);
      this.hotspotsContainer = hotspotsContainer;
      this.addHotspots();

      // Add event listener to close tooltips when clicking elsewhere
      if (this.enableTooltip) {
        this.addCloseTooltipListener();
      }
    }

    addHotspots() {
      this.data.forEach((hotspot, index) => {
        const buttonContainer = jQuery('<div class="mad-hotspots__button-container"></div>').css({
          top: `${hotspot.position_y}%`,
          left: `${hotspot.position_x}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          opacity: 0
        });

        const button = jQuery('<a></a>', {
          text: index + 1,
          class: 'mad-hotspots__button',
        });

        buttonContainer.append(button);
        this.hotspotsContainer.append(buttonContainer);

        // Animate the button appearance with zoom-in effect using GSAP
        gsap.to(buttonContainer, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          clearProps: 'transform'
        });

        // Add tooltip if enabled and data is present
        if (this.enableTooltip && (hotspot.label || hotspot.description || hotspot.link)) {
          this.createTooltip(buttonContainer, hotspot);
        }

        // Event handling
        if (this.isMobile) {
          this.handleMobileInteraction(button, buttonContainer, hotspot);
        } else {
          this.handleDesktopInteraction(button, buttonContainer, hotspot);
        }
      });

      // Schedule the first pulsation only if pulsation is enabled
      if (this.enablePulsation) {
        this.scheduleNextPulsation();
      }
    }

    createTooltip(buttonContainer, hotspot) {
      const tooltip = jQuery('<div class="mad-hotspots__tooltip"></div>');
      const tooltipContent = jQuery('<div class="mad-hotspots__tooltip-content"></div>');
    
      // Close button (using WordPress dashicon)
      const closeButton = jQuery('<button type="button" class="mad-hotspots__tooltip-close" aria-label="Close tooltip"><span class="dashicons dashicons-no-alt"></span></button>');
      tooltipContent.append(closeButton);
    
      // Add label
      if (hotspot.label) {
        const label = jQuery('<h6 class="mad-hotspots__tooltip-label"></h6>').text(hotspot.label);
        tooltipContent.append(label);
      }
    
      // Add description
      if (hotspot.description) {
        const description = jQuery('<div class="mad-hotspots__tooltip-description"></div>').html(hotspot.description);
        tooltipContent.append(description);
      }
    
      // Add link
      if (hotspot.link) {
        const linkText = hotspot.link_text || 'Read more';
        const link = jQuery('<a class="mad-hotspots__tooltip-link mad-button mad-button1 dense"></a>')
          .attr('href', hotspot.link)
          .text(linkText);
    
        // Conditionally add target="_blank"
        if (hotspot.open_in_new_tab) {
          link.attr('target', '_blank');
        }
    
        tooltipContent.append(link);
      }
    
      tooltip.append(tooltipContent);
      buttonContainer.append(tooltip);
    
      // Positioning
      tooltip.css({
        display: 'none' // Initially hide the tooltip
      });
    
      // Close button event
      closeButton.on('click', (e) => {
        e.stopPropagation(); // Prevent the click from triggering other events
        this.hideTooltip(buttonContainer);
      });
    }
    
    
    handleDesktopInteraction(button, buttonContainer, hotspot) {
      let hoverTimeout; // Declare a variable to store the timeout ID
      
      if (this.enableTooltip && (hotspot.label || hotspot.description || hotspot.link)) {
        // Hover to show tooltip with a delay
        button.on('mouseenter', () => {
          hoverTimeout = setTimeout(() => {
            // Hide any other open tooltips
            this.hotspotsContainer.find('.tooltip-open').each((index, btnContainer) => {
              if (btnContainer !== buttonContainer[0]) {
                this.hideTooltip(jQuery(btnContainer));
              }
            });
    
            this.showTooltip(buttonContainer);
          }, 200); // 200ms delay before showing the tooltip
        });
    
        // Clear the timeout if the mouse leaves before the delay finishes
        button.on('mouseleave', () => {
          clearTimeout(hoverTimeout); // Cancel showing the tooltip if mouse leaves
        });
      }
    
      // Click to trigger clickCallback if provided
      button.on('click', (e) => {
        e.preventDefault();
    
        // Close tooltip if open
        if (this.enableTooltip) {
          this.hideTooltip(buttonContainer);
        }
    
        // Execute clickCallback if provided
        if (typeof hotspot.clickCallback === 'function') {
          hotspot.clickCallback();
        }
    
        // Stop pulsation temporarily if it is enabled
        if (this.enablePulsation) {
          this.isPulsating = false;
          clearTimeout(this.pulsationTimeout);
          this.pulsationTimeout = setTimeout(() => {
            this.isPulsating = true;
            this.scheduleNextPulsation();
          }, 30000);
        }
      });
    }
    
    handleMobileInteraction(button, buttonContainer, hotspot) {
      button.on('click', (e) => {
        e.preventDefault();

        if (this.enableTooltip && (hotspot.label || hotspot.description || hotspot.link)) {
          if (buttonContainer.hasClass('tooltip-open')) {
            // Tooltip is open, close it

            // Execute clickCallback if provided
            if (typeof hotspot.clickCallback === 'function') {
              hotspot.clickCallback();
            }

            // Close the tooltip
            this.hideTooltip(buttonContainer);

            // Stop pulsation temporarily if it is enabled
            if (this.enablePulsation) {
              this.isPulsating = false;
              clearTimeout(this.pulsationTimeout);
              this.pulsationTimeout = setTimeout(() => {
                this.isPulsating = true;
                this.scheduleNextPulsation();
              }, 30000);
            }
          } else {
            // Tooltip is closed, open it
            // Hide any other open tooltips
            this.hotspotsContainer.find('.tooltip-open').each((index, btnContainer) => {
              this.hideTooltip(jQuery(btnContainer));
            });

            this.showTooltip(buttonContainer);
          }
        } else {
          // No tooltip, execute clickCallback immediately
          if (typeof hotspot.clickCallback === 'function') {
            hotspot.clickCallback();
          }

          // Stop pulsation temporarily if it is enabled
          if (this.enablePulsation) {
            this.isPulsating = false;
            clearTimeout(this.pulsationTimeout);
            this.pulsationTimeout = setTimeout(() => {
              this.isPulsating = true;
              this.scheduleNextPulsation();
            }, 30000);
          }
        }
      });
    }

    addCloseTooltipListener() {
      // Close tooltips when clicking outside of hotspots and tooltips
      this.imageContainer.on('click', (e) => {
        const target = jQuery(e.target);

        // Check if the click is outside of any hotspot buttons or tooltips
        if (
          !target.closest('.mad-hotspots__button-container').length &&
          !target.closest('.mad-hotspots__tooltip').length
        ) {
          // Close all open tooltips
          this.hotspotsContainer.find('.tooltip-open').each((index, btnContainer) => {
            this.hideTooltip(jQuery(btnContainer));
          });
        }
      });
    }

    showTooltip(buttonContainer) {
      const tooltip = buttonContainer.find('.mad-hotspots__tooltip');
      
      // Show the tooltip to get its dimensions
      tooltip.stop(true, true).fadeIn(200, () => {
        
        // Get tooltip position and dimensions
        const tooltipOffset = tooltip.offset();
        const tooltipWidth = tooltip.outerWidth();
        
        // Get viewport width
        const viewportWidth = jQuery(window).width();
        
        let newMargin = 0;
    
        // Check if the tooltip overflows on the left side
        if (tooltipOffset.left < 0) {
          newMargin = Math.abs(tooltipOffset.left) + 10; // 10px extra padding
          tooltip.css('margin-left', `${newMargin}px`);
        }
        
        // Check if the tooltip overflows on the right side
        else if (tooltipOffset.left + tooltipWidth > viewportWidth) {
          const overflowRight = tooltipOffset.left + tooltipWidth - viewportWidth;
          newMargin = overflowRight + 10; // 10px extra padding
          tooltip.css('margin-left', `-${newMargin}px`);
        }
        
        // After ensuring proper position, add the open class
        buttonContainer.addClass('tooltip-open');
      });
    }
    
    hideTooltip(buttonContainer) {
      const tooltip = buttonContainer.find('.mad-hotspots__tooltip');
      tooltip.stop(true, true).fadeOut(200);
      buttonContainer.removeClass('tooltip-open');
    }

    scheduleNextPulsation() {
      if (!this.enablePulsation) return;

      const randomTime = this.getRandomTime(5000, 10000);
      this.pulsationTimeout = setTimeout(() => {
        if (this.isPulsating) {
          // Select a random button container
          const randomIndex = Math.floor(Math.random() * this.data.length);
          const buttonContainer = this.hotspotsContainer.children('.mad-hotspots__button-container').eq(randomIndex);

          // Pulsate the selected button
          this.pulsateButton(buttonContainer.find('.mad-hotspots__button'));
        }

        // Schedule the next pulsation
        this.scheduleNextPulsation();
      }, randomTime);
    }

    pulsateButton(button) {
      // Pulsate animation using GSAP
      gsap.fromTo(button, {
        scale: 1
      }, {
        scale: 1.6,
        duration: 0.6,
        yoyo: true,
        repeat: 1,
        clearProps: 'transform'
      });
    }

    getRandomTime(min, max) {
      return Math.random() * (max - min) + min;
    }
  }

  window.Hotspots = Hotspots;
  jQuery(document).trigger('madHotspotsInitialized');
}, true);
