class CookieConsentAjax {
  constructor(nonce, ajaxurl) {
    this.nonce = nonce;
    this.ajaxurl = ajaxurl;
  }

  logConsent(actionType, data) {
    jQuery.ajax({
      url: this.ajaxurl,
      method: 'POST',
      data: {
        action: 'mad_log_consent_action',
        action_type: actionType,
        consent_data: JSON.stringify(data),
        security: this.nonce,
      },
      success(response) {
        if (!response.success) {
          console.error('Server logging failed:', response.data);
        }
      },
      error(error) {
        console.error('Error logging to server:', error);
      },
    });
  }
}

module.exports = CookieConsentAjax;
