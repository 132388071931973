class CookieBanner {
  constructor(cookieConsent) {
      this.cookieConsent = cookieConsent;
      this.init();
  }

  init() {
      // Cache DOM elements
      this.$container = jQuery('.mad-cookie-banner__container');
      this.$acceptAllButton = this.$container.find('.mad-cookie-banner__button--accept-all');
      this.$customizeButton = this.$container.find('.mad-cookie-banner__button--customize');

      // Bind event handlers
      this.bindEvents();

      // Determine whether to show or hide the banner
      if (this.cookieConsent.checkCookie('hide_cookie_banner')) {
        this.hide();
      } else if (!this.cookieConsent.hasConsent()) {
          this.show();
      } else {
          this.hide();
      }
  }

  bindEvents() {
      this.$acceptAllButton.on('click', () => {
          this.onAcceptAll();
      });

      this.$customizeButton.on('click', () => {
          this.onCustomize();
      });
  }

  onAcceptAll() {
      // Delegate consent granting to CookieConsent
      this.cookieConsent.acceptAll();
  }

  onCustomize() {
      // Show the modal through CookieModal
      this.cookieConsent.modal.show();
  }

  show() {
      this.$container.show();
  }

  hide() {
      this.$container.hide();
  }
}

module.exports = CookieBanner;
