window.madScrollTo = function(target, offsetElements = ['#wpadminbar', '.mad-navbar__section'], callback = null) {
  var $target = jQuery(target);

  // Calculate the total offset if the elements are present
  var offset = 0;
  jQuery.each(offsetElements, function(index, element) {
    if (jQuery(element).length) {
      offset += jQuery(element).outerHeight();
    }
  });

  // Calculate the distance to scroll
  var targetOffset = $target.offset().top - offset;
  var currentScroll = jQuery(window).scrollTop();
  var distance = Math.abs(targetOffset - currentScroll);

  // Set a base speed (milliseconds per pixel)
  var distancePerPixel = 0.3;
  var duration = distance * distancePerPixel;
  
  jQuery('html, body').stop().animate({
    'scrollTop': targetOffset
  }, duration, 'swing', function() {
    if (callback && typeof callback === 'function') {
      callback();
    }
  });
}

function updateSectionAnchorsTop() {
  jQuery('section.brz-section').each(function(index, element) {
    const $section = jQuery(element);
    const $anchor = $section.find('.brz-section-anchor');

    if ($anchor.length) {
      // Refresh the scroll offset and set the top of the anchor
      var offset = 0;
      var offsetElements = ['#wpadminbar', '.mad-navbar__section'];
      jQuery.each(offsetElements, function(index, element) {
        if (jQuery(element).length) {
          offset += jQuery(element).outerHeight();
        }
      });

      $anchor.css('top', `-${offset}px`);
    }
  });
}

jQuery(document).ready(function() {
  jQuery('section.brz-section').each(function(i, element) {
    const $section = jQuery(element);
    const sectionId = $section.attr('id');

    if (sectionId) {
      // Create the anchor div
      const $anchor = jQuery('<div class="brz-section-anchor"></div>');

      // Assign the same id to the anchor and remove it from the section
      $anchor.attr('id', sectionId);
      $section.removeAttr('id');

      // Insert the anchor before the section
      $section.prepend($anchor);

      // Calculate the scroll offset and set the top of the anchor
      var offset = 0;
      var offsetElements = ['#wpadminbar', '.mad-navbar__section'];
      jQuery.each(offsetElements, function(index, element) {
        if (jQuery(element).length) {
          offset += jQuery(element).outerHeight();
        }
      });

      $anchor.css('top', `-${offset}px`);
    }
  });

  // Smooth scrolling for anchor links
  jQuery('a:not(.brz-a)[href^="#"]').on('click', function(event) {
    var target = this.hash;
    if (target == "") return;

    event.preventDefault();

    window.madScrollTo(target, ['#wpadminbar', '.mad-navbar__section'], function() {
      history.pushState(null, null, target);
    });
  });

  // Refresh the top value of section anchors on window resize
  jQuery(window).on('resize', updateSectionAnchorsTop);
});
