require('./groupPanel')
require('./tabs')
require('./timeline')
require('./scrollToTop')
require('./slider')
require('./autoSlider')
require('./accodion')
require('./playButton')
require('./accent')
require('./comparison-table')
require('./hotspots')
require('./hotspot-image')
require('./language-switch')