const Animation = require('./Animation');

class FadeInItemsAnimation extends Animation {
  constructor(parent, x, y, duration = 0.4) {
    super(parent, x, y, duration);
    this.children = this.getChildren(parent);
    this.createTimeline();
    super.setupScrollTrigger();
    this.delay = 0.6;
  }

  getChildren(parent) {
    if (parent.hasClass('brz-columns')) {
      return parent.find('.brz-column__items').first().children();
    } else if (parent.hasClass('brz-row__container')) {
      return parent.find('.brz-row').first().children();
    } else {
      return parent.children();
    }
  }

  createTimeline() {
    this.timeline = super.createTimeline();

    this.timeline.fromTo(
      this.children,
      {
        opacity: 0,
        x: this.x,
        y: this.y
      },
      {
        opacity: 1,
        x: 0,
        y: 0,
        duration: this.duration,
        delay: this.delay,
        stagger: 0.1
      }
    );

    return this.timeline;
  }
}

module.exports = FadeInItemsAnimation;
