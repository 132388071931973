$dependencyManager.addDependencyCallback('gsap', () => {
  function addGsapAnimation(element) {
    // Check if the animation data attribute is already added to prevent duplicates
    if (!element.attr('data-play-button-animation')) {
      const ripples = element.find('.ripple');
      
      gsap.fromTo(ripples, {
        height: '100%',
        width: '100%',
        opacity: 1
      }, {
        height: '150%',
        width: '150%',
        opacity: 0,
        duration: 1.2,
        ease: "power2.out",
        repeat: -1,
        repeatDelay: 3,
        delay: Math.random() * 2
      });
      
      // Mark element to indicate that the animation was added
      element.attr('data-play-button-animation', 'true');
    }
  }

  // Iterate over existing play buttons and add animation if not already added
  jQuery('.mad-play-button').each((index, element) => {
    const playButton = jQuery(element);
    addGsapAnimation(playButton);
  });

  // Listen for the play_button_added event to add the animation to dynamically added buttons
  jQuery('body').on('play_button_added', (event, button) => {
    // Iterate over new play buttons and add animation if not already added
    jQuery(button).find('.mad-play-button').each((index, element) => {
      const playButton = jQuery(element);
      addGsapAnimation(playButton);
    });
  });
});
