$dependencyManager.addDependencyCallback('plyr', () => {

  class VideoLoader {
    constructor() {
      
    }

    load(container, videoUrl) {
      this.container = jQuery(container);
      this.videoUrl = videoUrl;

      // Initialize the video player
      return this.initPlayer();
    }

    initPlayer() {
      // Clear any existing content in the container
      this.container.empty();

      // Create a video element using jQuery
      const videoElement = jQuery('<div>', {
        'data-plyr-provider': 'vimeo',
        'data-plyr-embed-id': this.extractVimeoId(this.videoUrl)
      });

      // Append the video element to the container using jQuery
      this.container.append(videoElement);

      // Initialize Plyr on the video element
      return new Plyr(videoElement[0]);
    }

    extractVimeoId(url) {
      // Extract the Vimeo video ID from the URL
      const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
      const match = url.match(vimeoRegex);
      return match ? match[1] : null;
    }
  }

  // Expose the VideoLoader class globally
  window.VideoLoader = VideoLoader;

});
