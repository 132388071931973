$dependencyManager.addDependencyCallback("swiper", () => {
  class StaffSlider {
    constructor(container) {
      this.container = jQuery(container);
      this.slider = this.container.find("> .mad-slider");
      this.sliderId = this.container.data("slider-id");
      this.swiper = null;
      this.initSlider();
    }

    initSlider() {
      const slideCount = this.container.find(".mad-staff__container").length;
      this.swiper = new Swiper(
        `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__slides-container`,
        {
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 5,
          loop: true,
          // loopAdditionalSlides: 0,
          slideClass: "mad-staff__container",
          wrapperClass: "mad-slider__slides",
          navigation: {
            nextEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-next`,
            prevEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-prev`,
          },
          pagination: {
            el: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__pagination`,
            clickable: true,
          },
          updateOnWindowResize: true,
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1100: {
              slidesPerView: 2,
              spaceBetween: 18,
            },
            1440: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          },
        }
      );

      this.container.css("opacity", "1");
    }
  }

  jQuery(".mad-slider__container--staff").each(function () {
    new StaffSlider(this);
  });
});
