jQuery(document).ready(function(){
  jQuery('.mad-scroll-to-top').show();

  jQuery(window).scroll(function(){
      if (jQuery(this).scrollTop() > 800) {
          jQuery('.mad-scroll-to-top').addClass('active');
      } else {
        jQuery('.mad-scroll-to-top').removeClass('active');
      }
  });

  jQuery('.mad-scroll-to-top').click(function(){
      jQuery('html, body').animate({ scrollTop: 0 }, 400);
      return false;
  });
});