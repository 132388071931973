const FadeInAnimation = require('./FadeInAnimation');
const FadeInItemsAnimation = require('./FadeInItemsAnimation');

class AnimationManager {
  static initialize() {
    AnimationManager.applyFadeInAnimations();
    AnimationManager.applyFadeInItemsAnimations();
  }

  static applyFadeInAnimations() {
    jQuery(".mad-animation__fade-in--bottom").each(function () {
      new FadeInAnimation(jQuery(this), 0, 60);
    });

    jQuery(".mad-animation__fade-in--top").each(function () {
      new FadeInAnimation(jQuery(this), 0, -60);
    });

    jQuery(".mad-animation__fade-in--left").each(function () {
      new FadeInAnimation(jQuery(this), -60, 0);
    });

    jQuery(".mad-animation__fade-in--right").each(function () {
      new FadeInAnimation(jQuery(this), 60, 0);
    });
  }

  static applyFadeInItemsAnimations() {
    jQuery(".mad-animation__fade-in-items--left").each(function () {
      new FadeInItemsAnimation(jQuery(this), -60, 0);
    });

    jQuery(".mad-animation__fade-in-items--right").each(function () {
      new FadeInItemsAnimation(jQuery(this), 60, 0);
    });

    jQuery(".mad-animation__fade-in-items--top").each(function () {
      new FadeInItemsAnimation(jQuery(this), 0, -100);
    });

    jQuery(".mad-animation__fade-in-items--bottom").each(function () {
      new FadeInItemsAnimation(jQuery(this), 0, 100);
    });
  }
}

module.exports = AnimationManager;
