$dependencyManager.addDependencyCallback("swiper", () => {
  class TimelineSlider {
    constructor(container) {
      this.container = jQuery(container);
      this.slider = this.container.find("> .mad-slider");
      this.sliderId = this.container.data("slider-id");
      this.autoplay = this.container.data("autoplay");
      this.autoplayDelay = parseInt(this.container.data("autoplay-delay"), 10);
      this.loop = this.slider.data("loop") == true;

      this.slidesPerView = this.slider.data("slides-count") || 1;
      this.slidesCount = this.slider.find('.mad-slider__slide').length;
      this.swiper = null;

      // Get the pagination items
      this.paginationItems = this.container.find(
        ".mad-slider__pagination-list-element"
      );

      this.progressBar = this.container.find('.mad-slider__progressbar');

      // Initialize the slider
      this.initSlider();

      // Ensure visibility after initialization
      this.container.css("opacity", "1");
    }

    initSlider() {
      // Initialize the slider with the progressbar pagination
      this.swiper = new Swiper(
        `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__slides-container`,
        {
          slidesPerView: this.slidesPerView,
          loop: this.loop,
          centeredSlides: false,
          slideClass: "mad-slider__slide",
          wrapperClass: "mad-slider__slides",
          navigation: {
            nextEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-next`,
            prevEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-prev`,
          },
          updateOnWindowResize: true,

          // Conditionally apply autoplay settings
          autoplay: this.autoplay
            ? {
                delay: this.autoplayDelay,
                disableOnInteraction: true,
              }
            : false,
        }
      );

      // Update pagination on initialization
      this.updatePagination();

      // Update pagination when slide changes
      this.swiper.on("slideChange", () => {
        this.updatePagination();
      });

      // Add click handlers to pagination items
      this.paginationItems.each((index, element) => {
        jQuery(element).on("click", () => {
          this.swiper.slideToLoop(index);
        });
      });
    }

    updatePagination() {
      // Get the real index of the current slide
      const realIndex = this.swiper.realIndex;

      this.progressBar.width(`${(realIndex / (this.slidesCount - 1)) * 100}%`);
      console.log('update', realIndex, this.slidesCount, `${(realIndex / (this.slidesCount - 1)) * 100}%`)

      // Remove all classes from pagination items
      this.paginationItems.removeClass("past current future");

      // Loop over pagination items and add classes based on their position
      this.paginationItems.each((index, element) => {
        if (index < realIndex) {
          jQuery(element).addClass("past");
        } else if (index === realIndex) {
          jQuery(element).addClass("current");
        } else {
          jQuery(element).addClass("future");
        }
      });
    }
  }

  jQuery(".mad-slider__container--timeline").each(function () {
    new TimelineSlider(this);
  });
});
