// CookieModal.js

class CookieModal {
  constructor(cookieConsent) {
    this.cookieConsent = cookieConsent;
    this.init();
  }

  init() {
    // Cache DOM elements
    this.$container = jQuery(".mad-cookie-modal__container");
    this.$modal = this.$container.find(".mad-cookie-modal");
    this.$form = this.$modal.find(".mad-cookie-modal__form");
    this.$closeButton = this.$modal.find(".mad-cookie-modal__button--close");
    this.$acceptAllButton = this.$modal.find(".mad-cookie-modal__button--accept-all"); // Added
    this.$categoryHeaders = this.$modal.find(
      ".mad-cookie-modal__category-header"
    );
    this.$categoryCheckboxes = this.$modal.find(
      ".mad-cookie-modal__category-checkbox"
    );
    this.$cookieCheckboxes = this.$modal.find(
      ".mad-cookie-modal__cookie-checkbox"
    );

    // Bind event handlers
    this.bindEvents();
  }

  bindEvents() {
    // Bind event handlers using event delegation

    // Category Expand/Collapse
    this.$modal.on("click", ".mad-cookie-modal__category-header", (e) => {
      e.preventDefault();
      const $header = jQuery(e.currentTarget);
      this.onCategoryToggle($header);
    });

    // Category Checkbox Change
    this.$modal.on("change", ".mad-cookie-modal__category-checkbox", (e) => {
      const $checkbox = jQuery(e.currentTarget);
      this.onCategoryCheckboxChange($checkbox);
    });

    // Prevent Event Propagation on Category Checkbox Click
    this.$modal.on("click", ".mad-cookie-modal__category-checkbox", (e) => {
      e.stopPropagation();
    });

    // Cookie Checkbox Change
    this.$modal.on("change", ".mad-cookie-modal__cookie-checkbox", (e) => {
      const $checkbox = jQuery(e.currentTarget);
      this.onCookieCheckboxChange($checkbox);
    });

    // Form Submission
    this.$form.on("submit", (e) => {
      this.onFormSubmit(e);
    });

    // Close Modal Button
    this.$closeButton.on("click", () => {
      this.onClose();
    });

    this.$acceptAllButton.on("click", () => {
      this.onAcceptAll();
    });
  }

  show() {
    this.$container.addClass("active");
    this.updateCheckboxStates();
  }

  hide() {
    this.$container.removeClass("active");
  }

  onAcceptAll() {
    // Delegate consent granting to CookieConsent
    this.cookieConsent.acceptAll();
  }

  updateCheckboxStates() {
    const categoriesInfo = this.cookieConsent.settings.categoriesInfo;

    // Update category checkboxes
    this.$categoryCheckboxes.each((index, checkbox) => {
      const $checkbox = jQuery(checkbox);
      const categorySlug = $checkbox.data("category");
      const isNecessary = categoriesInfo[categorySlug]?.is_necessary;

      if (!isNecessary) {
        const categoryConsent =
          this.cookieConsent.getCookieCategoryConsent(categorySlug);
        $checkbox.prop("checked", categoryConsent);
      }
    });

    // Update cookie checkboxes
    this.$cookieCheckboxes.each((index, checkbox) => {
      const $checkbox = jQuery(checkbox);
      const cookieSlug = $checkbox.data("cookie");
      const categorySlug = $checkbox.data("category");
      const isNecessary = categoriesInfo[categorySlug]?.is_necessary;

      if (!isNecessary) {
        const cookieConsent = this.cookieConsent.getCookieConsent(cookieSlug);
        $checkbox.prop("checked", cookieConsent);
      }
    });
  }

  onCategoryToggle($header) {
    const $category = $header.closest(".mad-cookie-modal__category");
    const isActive = $category.hasClass("active");

    // Toggle active class
    this.$modal.find(".mad-cookie-modal__category").removeClass("active");
    if (!isActive) {
      $category.addClass("active");
    }
  }

  onCategoryCheckboxChange($checkbox) {
    const categorySlug = $checkbox.data("category");
    const isChecked = $checkbox.is(":checked");

    // Update all associated cookie checkboxes
    this.$cookieCheckboxes
      .filter(`[data-category="${categorySlug}"]`)
      .not(":disabled")
      .prop("checked", isChecked);
  }

  onCookieCheckboxChange($checkbox) {
    const categorySlug = $checkbox.data("category");
    const categoryCheckbox = this.$categoryCheckboxes.filter(
      `[data-category="${categorySlug}"]`
    );

    // Check if all cookies in the category are checked
    const allChecked = this.$cookieCheckboxes
      .filter(`[data-category="${categorySlug}"]`)
      .toArray()
      .every((cb) => jQuery(cb).is(":checked") || jQuery(cb).is(":disabled"));

    if (!categoryCheckbox.is(":disabled")) {
      categoryCheckbox.prop("checked", allChecked);
    }
  }

  onFormSubmit(event) {
    event.preventDefault();

    const categoriesInfo = this.cookieConsent.settings.categoriesInfo;

    // Revoke all consents except for necessary categories
    Object.keys(this.cookieConsent.settings.categoryCookieMap).forEach(
      (categorySlug) => {
        const isNecessary = categoriesInfo[categorySlug]?.is_necessary;
        if (!isNecessary) {
          this.cookieConsent.revokeCookieCategoryConsent(categorySlug);
        }
      }
    );

    // Grant consent based on selected categories and cookies
    this.$categoryCheckboxes.each((index, checkbox) => {
      const $checkbox = jQuery(checkbox);
      const categorySlug = $checkbox.data("category");
      const isNecessary = categoriesInfo[categorySlug]?.is_necessary;

      if ($checkbox.is(":checked") || isNecessary) {
        this.cookieConsent.giveCookieCategoryConsent(categorySlug);
      }
    });

    this.$cookieCheckboxes.each((index, checkbox) => {
      const $checkbox = jQuery(checkbox);
      const cookieName = $checkbox.data("cookie");
      const categorySlug = $checkbox.data("category");
      const isNecessary = categoriesInfo[categorySlug]?.is_necessary;

      if ($checkbox.is(":checked")) {
        this.cookieConsent.giveCookieConsent(cookieName);
      } else if (!isNecessary) {
        this.cookieConsent.revokeCookieConsent(cookieName);
      }
    });

    // Set cookie to hide the banner
    this.cookieConsent.setCookie("hide_cookie_banner", "true", 365);

    // Hide modal and banner
    this.hide();
    this.cookieConsent.banner.hide();

    // Apply consent changes
    this.cookieConsent.applyConsent();

    // Log consent
    this.cookieConsent.logConsent();
  }

  onClose() {
    this.hide();
  }
}

module.exports = CookieModal;
