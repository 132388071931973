jQuery('.brz-counter').each(function() {
  var counterSpan = jQuery(this).find('.brz-counter-numbers');
  var finalCount = jQuery(this).attr('data-brz-end');
  var hiddenElement = jQuery('<span/>')
                          .addClass('brz-counter-numbers')
                          .css({
                              'display': 'inline-block', 
                              'visibility': 'hidden', 
                              'position': 'absolute'
                          })
                          .text(finalCount)
                          .appendTo(counterSpan.parent());

  var finalWidth = hiddenElement.width();
  hiddenElement.remove();

  counterSpan.css('width', finalWidth + 'px');
});