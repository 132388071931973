const AnimationManager = require('./AnimationManager');

$dependencyManager.addDependenciesCallback(['gsap', 'scrollTrigger'], () => {
  gsap.registerPlugin(ScrollTrigger);
  AnimationManager.initialize();

  setInterval(() => {
    ScrollTrigger.refresh();
  }, 4000)
});
