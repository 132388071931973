function openBrizyPopupById(popupId) {
  var popupElement = jQuery(`[data-brz-popup="${popupId}"]`);
  if (popupElement.length && popupElement.hasClass('brz-popup')) {
    popupElement.addClass('brz-popup--opened');
    jQuery('html').addClass('brz-ow-hidden');
    if (window.Brz && typeof window.Brz.emit === 'function') {
        window.Brz.emit('elements.popup.open', popupElement.get(0));
    }
  } else if (popupElement.length && popupElement.hasClass('brz-popup2')) {
    // Assuming popup().show() is a jQuery extension; otherwise, you might need a different approach
    popupElement.popup().show();
  } else {
    console.error('Popup with specified ID not found or is not a brz-popup2.');
  }
}

function getBrizyPopupIdByChild(selector) {
  var array = jQuery(selector);
  var result;
  for (let i = 0; i < array.length; i++) {
    const madPopup = array[i];
    var popup = jQuery(madPopup).closest('.brz-popup2');
    if (popup.length) result = popup;
  }
  if (result) return result.attr('data-brz-popup');
}

function openBrizyPopupByChild(selector) {
  openBrizyPopupById(getBrizyPopupIdByChild(selector));
}

function brizyPopupAddCloseCallback(id, callback) {
  var popupElement = jQuery(`[data-brz-popup="${id}"]`);

  if (!popupElement.length) {
    console.error('Popup with specified ID not found.');
    return;
  }

  // Monitor class changes on the popup element
  const observer = new MutationObserver((mutationsList) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        // Check if the popup no longer has the .brz-popup2--opened class
        if (!popupElement.hasClass('brz-popup2--opened')) {
          callback();
        }
      }
    }
  });

  observer.observe(popupElement[0], {
    attributes: true,
    attributeFilter: ['class']
  });
}

function isBrizyPopupOpen(popupId) {
  var popupElement = jQuery(`[data-brz-popup="${popupId}"]`);
  return popupElement.hasClass('brz-popup--opened') || popupElement.hasClass('brz-popup2--opened');
}

window.openBrizyPopupById = openBrizyPopupById;
window.openBrizyPopupByChild = openBrizyPopupByChild;
window.getBrizyPopupIdByChild = getBrizyPopupIdByChild;
window.brizyPopupAddCloseCallback = brizyPopupAddCloseCallback;
window.isBrizyPopupOpen = isBrizyPopupOpen;
