class StaffFilterButtons {
    constructor(containerId) {
        this.containerId = containerId;
        this.$filterButtonsContainer = jQuery(`.mad-filter-buttons__container[data-staff-grid-id='${this.containerId}']`);
        this.$filterButtons = this.$filterButtonsContainer.find('.mad-filter-buttons .filter-button');
        this.$staffGrid = jQuery(`.mad-staff-grid[data-staff-grid-id='${this.containerId}']`);

        this.init();
    }

    init() {
        // Attach event listeners to filter buttons
        this.$filterButtons.on('click', (event) => {
            const $button = jQuery(event.currentTarget);
            const filterValue = $button.data('filter');

            // Remove the active class from all buttons and add it to the clicked button
            this.$filterButtons.removeClass('active');
            $button.addClass('active');

            this.filterItems(filterValue);

            // Safely refresh GSAP ScrollTrigger
            if (typeof ScrollTrigger !== 'undefined') {
                ScrollTrigger.refresh();
            }
        });
    }

    filterItems(filterValue) {
        const $items = this.$staffGrid.find('.mad-staff-grid__item');

        if (filterValue === 'all') {
            // Show all items
            $items.removeClass('disabled');
        } else {
            // Hide all items and show only the filtered ones
            $items.addClass('disabled');
            $items.filter(function () {
                const $item = jQuery(this);
                const categories = $item.data('categories').split(' ');
                return categories.includes(filterValue);
            }).removeClass('disabled');
        }
    }
}

jQuery(document).ready(function () {
    // Initialize the filter buttons for each staff grid found on the page
    jQuery('.mad-filter-buttons__container').each(function () {
        const containerId = jQuery(this).data('staff-grid-id');
        new StaffFilterButtons(containerId);
    });
});
