$dependencyManager.addDependencyCallback('swiper', () => {
    class ImageSlider {
        constructor(container) {
            this.container = jQuery(container);
            this.slider = this.container.find('> .mad-slider');
            this.sliderId = this.container.data('slider-id');
            this.autoplay = this.container.data('autoplay');
            this.autoplayDelay = parseInt(this.container.data('autoplay-delay'), 10);
            this.isGallery = this.slider.data('image-gallery') == true;
            this.loop = this.slider.data('loop') == true;

            this.thumbsCountDesktop = this.slider.data('thumbs-count-desktop') || 4;
            this.thumbsCountTablet = this.slider.data('thumbs-count-tablet') || 4;
            this.thumbsCountMobile = this.slider.data('thumbs-count-mobile') || 3;
            this.thumbsCountSmallMobile = this.slider.data('thumbs-count-small-mobile') || 2;

            this.slidesCount = this.slider.data('slides-count') || 1;
            this.swiper = null;
            this.thumbSwiper = null;

            // Initialize slider after checking the mode
            this.initSlider(this.thumbsCount);

            // Ensure visibility after initialization
            this.container.css('opacity', '1');
        }


        initSlider() {
            if (this.isGallery) {
                this.initImageGallery();
            } else {
                this.initRegularSlider();
            }
        }

        initImageGallery() {


            this.thumbSwiper = new Swiper(`.mad-slider__container--image-thumbs[data-slider-id="${this.sliderId}"] .mad-slider__thumbs`, {
                slidesPerView: this.thumbsCountSmallMobile,
                spaceBetween: 6,
                freeMode: true,
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
                loop: this.loop,
                slideClass: 'mad-slider__thumb',
                wrapperClass: 'mad-slider__thumbs-wrapper',
                breakpoints: {
                    768: {
                      slidesPerView: this.thumbsCountTablet,
                    },
                    1200: {
                      slidesPerView: this.thumbsCountDesktop,
                    }
                  }
            });

            this.swiper = new Swiper(`.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__slides-container`, {
                slidesPerView: this.slidesCount,
                loop: this.loop,
                centeredSlides: false,
                slideClass: 'mad-slider__slide',
                wrapperClass: 'mad-slider__slides',
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: true,
                },
                updateOnWindowResize: true,
                navigation: {
                    nextEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-next`,
                    prevEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-prev`,
                },
                thumbs: {
                    swiper: this.thumbSwiper, 
                },
                
            });
        }

        initRegularSlider() {
            // Initialize the regular slider with pagination
            this.swiper = new Swiper(`.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__slides-container`, {
                slidesPerView: this.slidesCount,
                loop: this.loop,
                centeredSlides: false,
                loop: true,
                slideClass: 'mad-slider__slide',
                wrapperClass: 'mad-slider__slides',
                navigation: {
                    nextEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-next`,
                    prevEl: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__button-prev`,
                },
                pagination: {
                    el: `.mad-slider__container[data-slider-id="${this.sliderId}"] .mad-slider__pagination`,
                    clickable: true,
                },
                updateOnWindowResize: true,

                // Conditionally apply autoplay settings
                autoplay: this.autoplay ? {
                    delay: this.autoplayDelay,
                    disableOnInteraction: true,
                } : false,
                updateOnWindowResize: true,
            });
        }
    }

    jQuery('.mad-slider__container--image').each(function() {
        new ImageSlider(this);
    });
});
