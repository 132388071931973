require('./brizyCounter.js');
require('./brizyPopup.js');
require('./animations');
require('./videoLoader.js');
require('./smoothScroll.js');

class Util {
  constructor() {

  }

  updateFragments(fragments) {
    if (fragments) {
      for (const fragmentSelector in fragments) {
        const fragmentContent = fragments[fragmentSelector];
        // console.log(fragmentSelector, fragmentContent);
        jQuery(fragmentSelector).replaceWith(fragmentContent);
      }
    }
  }

  blockElement(element, userOptions) {
    if (!element) return;
    element = jQuery(element);
  
    // Ensure the element has position: relative if it does not have a suitable positioning
    const currentPosition = element.css('position');
    if (!['relative', 'absolute', 'fixed', 'sticky'].includes(currentPosition)) {
      element.css('position', 'relative');
    }
  
    const defaultOptions = {
      overlay: {
        backgroundColor: '#212121',
        opacity: 0.25,
      },
      icon: {
        size: 'medium',
        color: '#000',
        positionX: 'center',
        positionY: 'center'
      },
    };
  
    const options = this.deepMerge({}, defaultOptions);
    if (userOptions) this.deepMerge(options, userOptions);
  
  
    const loadingOverlay = jQuery('<div class="mad-loading-overlay"></div>')
      .addClass(`px-${options.icon.positionX}`)
      .addClass(`py-${options.icon.positionY}`);
  
    const loadingOverlayBackground = jQuery('<div class="mad-loading-overlay__bg"></div>').css({
      'background-color': options.overlay.backgroundColor,
      'opacity': options.overlay.opacity,
    });
  
    const loadingIconSizeClass = `s-${options.icon.size}`;
    const loadingIcon = jQuery('<div class="mad-loading"><div></div><div></div><div></div><div></div></div>')
      .addClass(loadingIconSizeClass)
      .css({
        'color': options.icon.color
      });
  
    loadingOverlay.append(loadingIcon);
    loadingOverlay.append(loadingOverlayBackground);
  
    element.append(loadingOverlay);
  }
  

  unblockElement(element) {
    if (!element) return;
    element = jQuery(element)

    jQuery(element).find('.mad-loading-overlay').remove()
  }

  deepMerge(target, source) {
    Object.keys(source).forEach(key => {
      if (source[key] && typeof source[key] === 'object') {
        if (!target[key]) {
          target[key] = {};
        }
        this.deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    });
    return target;
  }

	isValidJson(rawJson) {
		try {
			var json = JSON.parse( rawJson );

			return ( json && 'object' === typeof json );
		} catch ( e ) {
			return false;
		}
	}

}

window.$madUtil = new Util()